import React from 'react';
import styled from 'styled-components';

const SpacerContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 4rem;
  margin-top: var(--spacing-m);
`;

export default function PlainSpacer(node) {
  const { title } = node;
  return (
      <SpacerContainer />
  );
}
