import React from 'react';
import styled from 'styled-components';

const ContentContainer = styled.div`
  width: 100%;
  margin: var(--spacing-xl) auto;
  color: var(--font-color);
  position: relative;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
  }
`;

const Title = styled.h3`
  font-size: 2rem;
  font-family: var(--font-small);
  line-height: 1.8;
  font-weight: 100;
  margin: 0;
  padding: 0;
  
  @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 1.5rem;   
  }
`;

const Content = styled.div`
  font-size: 1.2rem;
  font-family: var(--font-large);
  font-weight: 200;
  line-height: 1.4;
  width: 95%;
  
  @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 1rem;   
  }
`;

export default function ContentBlock(node) {
  const { title, content } = node;
  console.warn(title);
  return (
    <ContentContainer>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </ContentContainer>
  );
}
