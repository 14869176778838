import React, { useState } from 'react';
import styled from 'styled-components';
import useForm from '../utils/useForm';

const ContactFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  margin-top: var(--spacing-m);
  padding-bottom: var(--spacing-xxl);
  position: relative;
  
  @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
    flex-direction: column;   
    margin: var(--spacing-s) auto;
    padding-bottom: var(--spacing-m);
    width: 90%;
  }
  
  /* Portrait */
  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
    flex-direction: column;   
    margin: var(--spacing-s) auto;
    padding-bottom: var(--spacing-m);
    width: 100%;
  }
`;

const BlueCircle = styled.div`
  width: 24rem;
  height: 24rem;
  border-radius: 50%;
  background-color: #00a5d9;
  opacity: 0.1;
  position: absolute;
  left: 15%;
  top: -10%;
  z-index: 1;
`;

const BusinessCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  font-family: var(--font-small);
  position: relative;
  z-index: 2;

  .title {
    color: #bdbdbd;
  }
  
  @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
    margin: 0 auto;   
  }
  
  /* Portrait */
  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
    width: 100%;
    margin: 0 auto;
  }
`;


const Title = styled.h1`
  font-family: var(--font-large);
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 200;
  font-style: normal;
  width: 100%;
  z-index: 2;
  position: relative;
  color: #00A5D9;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 2rem;
  }
`;

const SubTitle = styled.h2`
  font-family: var(--font-large);
  font-size: 2.0rem;
  line-height: 1;
  font-weight: 300;
  font-style: normal;
  text-decoration: uppercase;
  width: 100%;
  z-index: 2;
  position: relative;
  color: #202020;
  margin: 0 0 var(--spacing-m) 0;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 2rem;
  }
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  font-family: var(--font-small);
  position: relative;
  z-index: 2;
  
  @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
    margin-top: var(--spacing-m);   
  }
  
  /* Portrait */
  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
    width: 100%;
    margin-top: var(--spacing-m); 
  }
`;

const ContentTitle = styled(Title)`
  font-weight: 600;
  color: #00A5D9;
  
  .subTitleColor {
    display: inline;
    font-weight: 100;
    color: #00A5D9;
  }
  margin-bottom: var(--spacing-m);
  
  @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
    margin: 0 auto;   
  }
  
  /* Portrait */
  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
    width: 100%;
    margin: 0 auto;  
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-family: var(--font-small);
  font-size: 0.9rem;
  color: #000000;
  line-height: 1.4;
  margin-bottom: var(--spacing-s);
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
  transition: 0.4s;

  &:hover {
    color: #000000;
    border-color: #000000;
    transition: 0.4s;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }
`;

const InputText = styled.input`
  background: transparent;
  font-family: var(--font-small);
  font-size: 0.9rem;
  font-weight: bold;
  color: #000000;
  line-height: 1.4;
  margin: var(--spacing-s) var(--spacing-m) var(--spacing-s) 0;
  border: 0;
  outline: none;
  transition: 0.4s;

  ::placeholder {
    color: #A0A0A0;
    font-weight: 100;
    transition: 0.4s;
  }

  &:focus {
    color: #000000;
    transition: 0.4s;
  }

  &:valid {
    color: #000000;
    transition: 0.4s;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }
`;

const TextArea = styled.textarea`
  background: transparent;
  font-family: var(--font-small);
  font-size: 0.9rem;
  font-weight: bold;
  color: #000000;
  line-height: 1.4;
  resize: none;
  margin: var(--spacing-s) var(--spacing-m) var(--spacing-s) 0;
  border: 0;
  height: 12rem;
  outline: none;

  ::placeholder {
    color: #A0A0A0;
    font-weight: 100;
    transition: 0.4s;
  }

  &:focus {
    color: #000000;
    transition: 0.4s;
  }

  &:valid {
    color: #000000;
    transition: 0.4s;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }
`;

const PrimaryButton = styled.input`
  margin-top: var(--spacing-s);
  font-family: var(--font-small);
  font-size: 1.2rem;
  padding: var(--spacing-s) var(--spacing-m);
  width: 100%;
  background: #ffffff;
  cursor: pointer;
  border: 2px solid #202020;
  transition: 0.4s;
  border-radius: 50px;

  &:hover {
    color: #bdbdbd;
    border-color: #bdbdbd;
    transition: 0.4s;
  }
`;

const ThanksMessage = styled.h3`
  font-family: var(--font-large);
  font-size: 3rem;
  color: var(--thertary-color);
  margin: var(--spacing-xxl) auto;
  text-align: center;
  font-style: italic;
  text-transform: uppercase;
`;

const OpeningDays = styled.div`
  margin: var(--spacing-m) 0;
  @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;   
  }
  
  /* Portrait */
  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
    width: 100%;
  }
`;
const OpeningDay = styled.div`
  font-size: 1.2rem;
  font-family: var(--font-large);
  font-weight: 200;
  color: #202020;
`;

const ContactLink = styled.a`
  font-size: 1.2rem;
  font-family: var(--font-large);
  font-weight: 200;
  color: #202020;
  display: block;
  
  &:hover {
    color: #00A5D9;
  } 
`

const Day = styled.span`
  color: #999;
  display: inline-block;
  width: 9rem;
`;

const Address = styled.div`
  margin: var(--spacing-m) 0;
`;

const Line = styled.div`
  font-size: 1.2rem;
  font-family: var(--font-large);
  font-weight: 200;
  color: #202020;
`

const ContactTitle = styled.h2`
  font-family: var(--font-large);
  font-size: 2rem;
  font-weight: 200;
  color: #00A5D9;
  margin: var(--spacing-m) 0 0 0;
  
  @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
    margin: 0 auto;   
  }
  
  /* Portrait */
  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
    width: 100%;
    margin: 0 auto;  
  }
`;

export default function ContactForm(node) {
  const { title, subTitle, background } = node;

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const { values, updateValue } = useForm({
    name: '',
    email: '',
    phone: '',
    remark: '',
  });

  async function submitForm(event) {
    event.preventDefault();
    setLoading(true);

    const body = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      remark: values.remark,
    };
    const res = await fetch(`${process.env.GATSBY_SERVERLESS_BASE}/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const text = JSON.parse(await res.text());

    if (res.status >= 400 && res.status < 600) {
      setLoading(false);
      setError(text.message);
    } else {
      setLoading(false);
      setError('');
      setMessage(
        'Bedankt voor u bericht\n' +
          'Ik neem zo spoedig mogelijk contact met u op.'
      );
    }
  }

  return (
    <>
      <a name={'contact'} />
      <ContactTitle>Contactgegevens</ContactTitle>
      <ContactFormContainer data-sal="slide-left"
                            data-sal-delay="50"
                            data-sal-easing="ease-in">
        <BusinessCard>
          <OpeningDays>
            <OpeningDay><Day>MAANDAG</Day> 09:00 - 15:00</OpeningDay>
            <OpeningDay><Day>DINSDAG</Day> 09:00 - 17:00</OpeningDay>
            <OpeningDay><Day>WOENSDAG</Day> 09:00 - 12:00</OpeningDay>
            <OpeningDay><Day>DONDERDAG</Day> 09:00 - 17:00</OpeningDay>
          </OpeningDays>
          <Address>
            <Line>Rietlanden 28</Line>
            <Line>6576 EB, Ooij</Line>
          </Address>
          <ContactLink href={'tel:0648178042'} title={'Bel ons'}>06-48178042</ContactLink>
          <ContactLink href={'mailto:info@schoonheidssalonlineke.nl'} title={'Mail ons'}>info@schoonheidssalonlineke.nl</ContactLink>
        </BusinessCard>
        <BlueCircle />
        <Contact>
          <ContentTitle>
            Stel <div className="subTitleColor">een vraag</div>
          </ContentTitle>

          <Label>
            <InputText
              placeholder="Naam"
              type="text"
              name="name"
              value={values.name}
              onChange={updateValue}
            />
          </Label>
          <Label>
            <InputText
              placeholder="E-mail"
              type="text"
              name="email"
              value={values.email}
              onChange={updateValue}
            />
          </Label>
          <Label>
            <InputText
              placeholder="Telefoon"
              type="text"
              name="phone"
              value={values.phone}
              onChange={updateValue}
            />
          </Label>
          <Label>
            <TextArea
              placeholder="Bericht"
              name="remark"
              onChange={updateValue}
              value={values.remark}
            />
          </Label>
          {error ? <p>Error: {error}</p> : ''}
          <PrimaryButton
            type="button"
            onClick={submitForm}
            value={loading ? 'Verzenden...' : 'Verstuur'}
            disabled={loading}
          />
        </Contact>
      </ContactFormContainer>
    </>
  );
}
