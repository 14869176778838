import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery} from "gatsby";
import waveVariant from '../assets/images/wave-beige.png';
import wave from '../assets/images/wave.png';
import categories from "../../../sanity/schemas/categories";

const SlidesOverview = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: var(--negative-spacing-m);
    padding: var(--spacing-m) var(--spacing-l);
    background-color: #00A5D9;
    border-radius: 4px;
    
    @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
    margin: 0 auto;   
  }
`

const SlideContainer = styled.div`
  width: 95%;
  z-index: 1;
  font-size: 1.2rem;
  font-family: var(--font-small);
  position: relative;

  .title {
    display: flex;
    align-items: center;
    color: #000000;
    cursor: pointer;
    transition: 0.8s;
    padding-top: var(--spacing-l);
    padding-bottom: var(--spacing-l);
  }

  .title:hover {
    color: rgba(255,255,255,0.8);
    transition: 0.4s;
    .circle {
      background-color: #fff;
    }

    .arrow {
      border: solid #000;
      border-width: 0 2px 2px 0;
    }
  }

  .active {
    color: rgba(255,255,255,0.8);
    transition: 0.4s;
    .circle {
      background-color: #fff;
    }

    .arrow {
      border: solid #000;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  .title + input {
    display: none;
  }

  .title + input + * {
    padding-left: 3rem;
    overflow: hidden;
    line-height: 0;
    opacity: 0;
    transition: line-height 0.2s, opacity .4s;
  }

  .title + input:checked + * {
    overflow: auto;
    transition: 0.8s;
    opacity: 1;
    line-height: 1.4;
    
    TreatmentDescription {
        margin-bottom: var(--spacing-s);
    }
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    border: 2px solid #000;
    height: 25px;
    width: 25px;
    margin-right: 10px;
    border-radius: 50%;
    transition: 0.4s;
  }

  .arrow {
    border: solid #fff;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(-45deg);
    transition: 0.4s;
  }
`;

const TreatmentTitle = styled.h3`
    font-family: var(--font-large);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: inherit;
    color: #202020;
`;
const TreatmentPrice = styled.span`
    font-family: var(--font-large);
    font-weight: 200;
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: inherit;
    color: #FFF;
`;

const TreatmentDescription = styled.p`
    font-family: var(--font-large);
    font-weight: 200;
    font-size: 1.2rem;
    line-height: inherit;
    color: #FFF;
    padding: 0;
`

const Content = styled.div``;

const TreamentItem = styled.div``;

const SlidesTitle = styled.h2`
    font-family: var(--font-large);
    font-size: 2rem;
    font-weight: 200;
    color: rgba(255,255,255,0.8);
    margin: var(--spacing-m) 0 var(--spacing-s) 0;
`

export default function SlideToggle(node) {
  const [checked, setChecked] = useState(new Map());

  useEffect(() => {

  }, [checked]);
    const { prices, pricesCategories } = useStaticQuery(graphql`
         query allTreatments {
            prices: allSanityPrices(sort: {fields: order, order: ASC}, filter: {isVisible: {eq: true}}) {
              nodes {
                category {
                  id
                }
                description
                id
                title,
                price,
                order
              }
            }
            pricesCategories: allSanityCategory(sort: {order: ASC, fields: order}, filter: {isVisible: {eq: true}}) {
                nodes {
                  id
                  title
                  description,
                  order
                }
              }
          }
  `);
    const getPricesPerCategory = (categoryId) => {
        return prices.nodes.filter(price => price.category.id === categoryId);
    }
    const treatmentsOverview = pricesCategories.nodes.reduce((treatments, category) => {
        treatments.set(category.title, getPricesPerCategory(category.id));
        return treatments;
    }, new Map());

    const treatmentsTemplate = [];
    treatmentsOverview.forEach((treatments, key, map) => {
        let safeKey = key.toLowerCase().replace(' ', '-');
        treatmentsTemplate.push((<SlideContainer key={safeKey}>
            <label
                className={checked.get(safeKey) === true ? 'active title' : 'title'}
                htmlFor={safeKey}
            >
                <div className="circle">
                    <div className="arrow" />
                </div>
                {key}
            </label>
            <input
                id={safeKey}
                onChange={() => {
                    setChecked(new Map(checked.set(safeKey, !checked.get(safeKey))));
                    console.log(checked);
                }}
                defaultChecked={checked.get(safeKey)}
                type="checkbox"
            />
            <Content>
                {treatments.map(treatment => {
                    return (<TreamentItem key={treatment.id}>
                        <TreatmentTitle>{treatment.title} <TreatmentPrice>- {treatment.price}</TreatmentPrice></TreatmentTitle>
                        {treatment.description && treatment.description !== '' ? (<TreatmentDescription dangerouslySetInnerHTML={{
                            __html: treatment.description.replace(/\\n/g, '<br />')
                        }}></TreatmentDescription>) : ''}
                    </TreamentItem>);
                })}
            </Content>

        </SlideContainer>));
    });
    const { title, uniqueElementNumber, content } = node;

    return (<SlidesOverview>
        <a name={'treatments'} />
        <SlidesTitle>Behandelingen</SlidesTitle>
        {treatmentsTemplate.map(template => template)}
    </SlidesOverview>);
}
