import React from 'react';
import styled from 'styled-components';
import { useStaticQuery } from 'gatsby';

const TreatmentsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: var(--spacing-m);
`;

const TreatmentCategoryContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: var(--spacing-m);

  h3 {
    font-size: 3rem;
    color: #999;
  }

  .description {
    font-size: 1.2rem;
    color: #202020;
  }
`;

const TreatmentsWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Treatment = styled.div`
  flex-basis: 25%;
  color: #202020;
`;

export default function TreatmentsOverview(node) {
  const { title } = node;
  const { treatments } = useStaticQuery(graphql`
    query AllTreatments {
      treatments: allSanityPrices {
        nodes {
          id
          title
          price
          description
          category {
            title
            description
            id
          }
        }
      }
    }
  `);
  const treatmentsPerCategory = treatments.nodes.reduce(
    (treatmentsList, treatment) => {
      if (
        Object.prototype.hasOwnProperty.call(
          treatmentsList,
          treatment.category.title
        )
      ) {
        treatmentsList[treatment.category.title].treatments.push({
          title: treatment.title,
          description: treatment.description,
          price: treatment.price,
          id: treatment.id,
        });
        return treatmentsList;
      }
      treatmentsList[treatment.category.title] = {
        description: treatment.category.description,
        title: treatment.category.title,
        id: treatment.category.id,
        treatments: [
          {
            id: treatment.id,
            title: treatment.title,
            description: treatment.description,
            price: treatment.price,
          },
        ],
      };
      return treatmentsList;
    },
    {}
  );
  return (
    <TreatmentsContainer>
      {Object.keys(treatmentsPerCategory).map(function (key) {
        const treatmentCategory = treatmentsPerCategory[key];
        return (
          <TreatmentCategoryContainer key={treatmentCategory.id}>
            <h3>{treatmentCategory.title}</h3>
            <p className="description">{treatmentCategory.description}</p>
            <TreatmentsWrapper>
              {treatmentCategory.treatments.map((treatment) => (
                <Treatment key={treatment.id}>
                  <em>{treatment.title}</em>
                  <p>{treatment.description}</p>
                  <p className="treatment__price">{treatment.price}</p>
                </Treatment>
              ))}
            </TreatmentsWrapper>
          </TreatmentCategoryContainer>
        );
      })}
    </TreatmentsContainer>
  );
}
