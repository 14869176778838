import { useState, useEffect } from 'react';

export default function useScrollToPlace(observerRef, targetRef, offset) {
    if (typeof window !== "undefined") {
        const { IntersectionObserver } = window;
        const [isIntersecting, setIntersecting] = useState(false);
        const observer = new IntersectionObserver(([entry]) => {
            setIntersecting(entry.isIntersecting);
            if (entry.intersectionRatio === 0) {
                targetRef.current.classList.add('visible');
            } else {
                targetRef.current.classList.remove('visible');
            }
        }, { rootMargin: offset });

        useEffect(() => {
            observer.observe(observerRef.current);
            // Remove the observer as soon as the component is unmounted
            return () => {
                observer.disconnect();
            };
        });

        return isIntersecting;
    }
}
