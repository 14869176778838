import React from 'react';
import imageUrlBuilder from '@sanity/image-url';
import styled from 'styled-components';
import client from './sanityClient';

const builder = imageUrlBuilder(client);
const urlFor = (source) => builder.image(source);
const ScrollSlideContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  height: 65rem;
  background: rgba(38, 12, 71, 0.8);
  overflow: hidden;
`;

const ScrollSlideImage = styled.img`
  width: 120%;
  height: 120%;
  object-fit: cover;
  position: relative;
  z-index: 1;
`;

const ScrollSlideTitle = styled.h1`
  font-size: 5rem;
  letter-spacing: -0.1rem;
  width: 30rem;
  line-height: 0.8;
  color: var(--secondary-color);
  text-transform: uppercase;
  font-family: futura-pt-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  text-align: center;
  margin-top: 10rem;
  margin-bottom: 0;
`;

const ScrollSlideNumber = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 2px solid #fff8f0;
  color: #fff8f0;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5rem;
  left: calc(50% - 25px);
  span {
    font-size: 1.5rem;
    font-family: poppins, sans-serif;
    color: var(--primary-color);
    font-weight: 600;
    line-height: 1;
  }
`;

const ScrollSlideSubtitle = styled.h2`
  font-size: 2rem;
  line-height: 1;
  width: 50%;
  color: var(--primary-color);
  text-transform: uppercase;
  font-family: futura-pt-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  text-align: left;
  margin-top: 0;
  margin-bottom: var(--spacing-xl);

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
`;

const ScrollSlideContent = styled.div`
  font-family: poppins, sans-serif;
  font-weight: 300;
  color: #fff8f0;
  font-size: 1rem;
  text-align: left;
  width: 50%;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
  }
`;

const ScrollSpacing = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  hr {
    width: 1px;
    background: var(--secondary-color);
    height: ${(props) => props.viewHeight || '10vh'};
    border: 0;
    margin: var(--spacing-m) 0;
  }
`;

const ScrollNextSlide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--spacing-m) 0;
`;

const ArrowDown = styled.div`
  width: 50px;
  height: 50px;
  border: 2px solid var(--primary-color);
  border-radius: 50px;

  :before {
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    border-color: var(--primary-color);
    content: '';
    display: inline-block;
    height: 1em;
    position: relative;
    top: 0.65em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 1em;
    transform: rotate(135deg);
  }
`;

export default function ScrollSlide(node) {
  const { slideNumber, title, subTitle, backgroundImage, content } = node;
  const scrollSlideImage = backgroundImage ? (
    <ScrollSlideImage src={urlFor(backgroundImage).url()} alt={title} />
  ) : (
    <></>
  );
  return (
    <>
      <ScrollSlideContainer>
        {scrollSlideImage}
        <ScrollSlideNumber>
          <span>{slideNumber}</span>
        </ScrollSlideNumber>
        <Wrapper>
          <ScrollSlideTitle>{title}</ScrollSlideTitle>
          <ScrollSpacing viewHeight="10vh">
            <hr />
          </ScrollSpacing>
          <ScrollSlideSubtitle>{subTitle}</ScrollSlideSubtitle>
          <ScrollSlideContent>{content}</ScrollSlideContent>
          <ScrollSpacing viewHeight="10vh">
            <hr />
          </ScrollSpacing>
          <ScrollNextSlide>
            <ArrowDown />
          </ScrollNextSlide>
          <ScrollSpacing viewHeight="5vh">
            <hr />
          </ScrollSpacing>
        </Wrapper>
      </ScrollSlideContainer>
    </>
  );
}
