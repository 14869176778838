import React from 'react';
import imageUrlBuilder from '@sanity/image-url';
import styled from 'styled-components';
import client from './sanityClient';

const builder = imageUrlBuilder(client);
const urlFor = (source) => builder.image(source);
const CallToActionContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  background: var(--primary-color);
  padding-top: var(--spacing-xl);
`;

const CallToActionFooter = styled.div`
  background: #00b5f7;
  padding: var(--spacing-xxl) 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: -150px;
  z-index: 1;
`;

const CallToActionBlock = styled.div`
  width: 50%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  padding: var(--spacing-s);
  display: flex;
  align-items: center;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
  }
`;

const CallToActionCircleImage = styled.img`
  position: absolute;
  left: -90px;
  width: 9rem;
  border-radius: 100px;
  height: 9rem;
  object-fit: cover;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    left: 95px;
    top: -140px;
  }
`;

const CallToActionPanel = styled.div`
  color: #fff;
  display: flex;
  width: 100%;
`;

const BackgroundPanel = styled.div`
  flex-basis: 20%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 0 0 10px;
`;

const ContentPanel = styled.div`
  flex-basis: 80%;
  background-color: var(--thertary-color);
  padding: var(--spacing-l);
  border-radius: 0 10px 10px 0;
`;

const Intro = styled.div`
  font-size: 1rem;
  font-family: var(--font-small);
  font-weight: 300;
  color: var(--primary-color);
  padding-left: var(--spacing-m);
  padding-bottom: var(--spacing-m);
`;

const IntroSlogan = styled.div`
  font-size: 3rem;
  font-family: var(--font-large);
  font-weight: 400;
  color: var(--primary-color);
  padding: 0 var(--spacing-m) var(--spacing-m) var(--spacing-m);
  text-indent: -0.1rem;
  font-style: italic;
  line-height: 1;
  margin-bottom: var(--spacing-m);

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 1.5rem;
  }
`;

const ActionPanel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    flex-direction: column;
  }
`;

const SecondaryButton = styled.a`
  display: block;
  border: 2px solid var(--primary-color);
  font-family: var(--font-small);
  font-size: 1rem;
  padding: var(--spacing-s) var(--spacing-m);
  margin-left: var(--spacing-m);
  color: var(--primary-color);
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  width: 80%;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    margin-bottom: var(--spacing-m);
    width: 100%;
  }
`;

const PrimaryButton = styled(SecondaryButton)`
  background: var(--primary-color);
  color: var(--thertary-color);
`;

const FooterSlogan = styled.h3`
  font-family: var(--font-large);
  font-size: 4rem;
  line-height: 1;
  text-indent: -0.1rem;
  width: 50%;
  margin: 0 auto;
  color: var(--primary-color);
  text-align: center;
  margin-top: var(--spacing-xl);
  text-transform: uppercase;
  font-style: italic;
  width: 30%;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }
`;

const FooterCompany = styled.span`
  font-family: var(--font-large);
  font-size: 4rem;
  line-height: 1;
  text-indent: -0.1rem;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  margin-top: var(--spacing-xl);
  text-transform: uppercase;
  font-style: italic;
  color: var(--thertary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
`;

const FooterCompanyInverse = styled(FooterCompany)`
  color: var(--primary-color);
  width: auto;
  display: inline;
  padding: 0;
  margin: 0;
`;

export default function Testimonials(node, companyName) {
  const companyNameSplitted = companyName.split('.');
  const {
    subBackground,
    circleImage,
    intro,
    slogan,
    secondaryButtonText,
    secondaryButtonUrl,
    primaryButtonText,
    primaryButtonUrl,
    footerSlogan,
  } = node;
  return (
    <>
      <CallToActionContainer className="callToAction">
        <CallToActionBlock>
          <CallToActionCircleImage src={urlFor(circleImage).url()} />
          <CallToActionPanel>
            <BackgroundPanel
              style={{ backgroundImage: `url(${urlFor(subBackground).url()})` }}
            />
            <ContentPanel>
              <Intro>{intro}</Intro>
              <IntroSlogan>{slogan}</IntroSlogan>
              <ActionPanel>
                <SecondaryButton
                  href={secondaryButtonUrl}
                  title={secondaryButtonText}
                >
                  {secondaryButtonText}
                </SecondaryButton>
                <PrimaryButton
                  href={primaryButtonUrl}
                  title={primaryButtonText}
                >
                  {primaryButtonText}
                </PrimaryButton>
              </ActionPanel>
            </ContentPanel>
          </CallToActionPanel>
        </CallToActionBlock>
        <CallToActionFooter>
          <FooterSlogan>{footerSlogan}</FooterSlogan>
          <FooterCompany>
            {companyNameSplitted.map((nameChunk, chunkIndex) => {
              if (chunkIndex < companyNameSplitted.length - 1) {
                return (
                  <span key={chunkIndex}>
                    {nameChunk}
                    <FooterCompanyInverse>.</FooterCompanyInverse>
                  </span>
                );
              }
              return <span key={chunkIndex}>{nameChunk}</span>;
            })}
          </FooterCompany>
        </CallToActionFooter>
      </CallToActionContainer>
    </>
  );
}
