import React from 'react';
import styled from 'styled-components';

const ParagraphContainer = styled.div`
  width: 75%;
  z-index: 1;
  padding-top: var(--spacing-l);
  font-size: 1.2rem;
  font-family: var(--font-small);
`;

export default function Paragraph(node) {
  const { paragraph } = node;
  return <ParagraphContainer>{paragraph}</ParagraphContainer>;
}
