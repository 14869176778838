import React from 'react';
import imageUrlBuilder from '@sanity/image-url';
import styled from 'styled-components';
import client from './sanityClient';
import circleBackground from '../../static/dbd-Rond-NightblueBlauw.jpeg';

const builder = imageUrlBuilder(client);
const urlFor = (source) => builder.image(source);
const RatesContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  left: 0;
  z-index: 1;
  padding: var(--spacing-xxl) 0;
  background-color: rgba(38, 12, 71, 0.8);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const RatesTitle = styled.h1`
  color: var(--primary-color);
  font-size: 2rem;
  font-family: var(--font-large);
  line-height: 1;
  margin: var(--spacing-xl) auto var(--spacing-m) auto;
  text-align: center;
  text-transform: uppercase;
  width: 50%;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
  }
`;

const RatesSubTitle = styled.p`
  color: #00b5f7;
  font-size: 0.9rem;
  font-family: var(--font-small);
  line-height: 1;
  margin: var(--spacing-m) auto var(--spacing-xl) auto;
  text-align: center;
  width: 50%;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
  }
`;

const RatesOverviewContainer = styled.div`
  width: 80%;
  background: var(--primary-color);
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  position: relative;
  margin-bottom: -140px;
  z-index: 2;
  box-shadow: 5px 10px 23px -9px rgba(0, 0, 0, 0.75);

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
`;

const RatesColumn = styled.div`
  text-align: center;
  flex-basis: 25%;
  max-width: 100%;
  font-family: var(--font-small);
  font-size: 0.9rem;
  line-height: 1.4;
  margin: var(--spacing-l) 0 var(--spacing-l) var(--spacing-l);
  padding-right: var(--spacing-l);
  border-right: 1px solid #eae4df;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    flex-basis: 100%;
    padding: var(--spacing-s);
    margin: var(--spacing-s);
    border-right: 0;
    border-bottom: 1px solid #eae4df;
    width: 95%;

    &:last-child {
      border-bottom: 0;
    }
  }
`;

const RatesCircle = styled.div`
  width: 8rem;
  height: 8rem;
  background: var(--thertary-color);
  background: url('${circleBackground}') no-repeat 0 0;
  border-radius: 50%;
  margin: 0 auto var(--spacing-m) auto;
  background-size: cover;
`;

const Price = styled.div`
  text-align: center;
  background: #00b5f7;
  padding: var(--spacing-s) var(--spacing-xs);
  color: var(--primary-color);
  border-radius: 5px;
  max-width: 100%;
  width: 100%;
  margin: 0 auto var(--spacing-m) auto;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 90%;
    width: 90%;
    text-align: center;
  }
`;

const Session = styled.p`
  color: #00b5f7;
  font-weight: bold;
`;

const Persons = styled(Session)`
  font-weight: normal;
  margin-bottom: var(--spacing-m);
`;

const Description = styled(Persons)`
  color: var(--thertary-color);
  font-size: 0.8rem;
  min-height: 10rem;
  width: 100%;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    min-height: auto;
  }
`;

export default function RatesOverview(node, rates) {
  const { title, subTitle, background } = node;
  return (
    <>
      <RatesContainer
        style={{ backgroundImage: `url(${urlFor(background).url()})` }}
      >
        <RatesTitle
          dangerouslySetInnerHTML={{
            __html: title.replace('\n', '<br />'),
          }}
        />
        <RatesSubTitle>{subTitle}</RatesSubTitle>
        <RatesOverviewContainer>
          {rates.map((price, priceIndex) => (
            <div key={priceIndex}>
              <RatesColumn>
                <RatesCircle />
                <Session>{price.title}</Session>
                <Persons>{price.persons}</Persons>
                <Description>{price.description}</Description>
                <Price>{price.price}</Price>
              </RatesColumn>
            </div>
          ))}
        </RatesOverviewContainer>
      </RatesContainer>
    </>
  );
}
