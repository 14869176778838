import React from 'react';
import styled from 'styled-components';
import logoSvg from '../assets/images/malu-wilz.png';

const Introduction = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`;

const IntroductionImage = styled.img`
  display: block;
  margin: 0 auto;
  object-fit: cover;
`;

const IntroductionTitle = styled.div`
  width: 50%;
  color: #bdbdbd;
  font-size: 2rem;
  font-weight: 500;
  font-family: var(--font-small);
  line-height: 0.9;
  text-align: left;
  margin-top: 8rem;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 5rem;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
`;

const IntroductionText = styled.div`
  color: #000000;
  font-size: 1.2rem;
  width: 50%;
  font-family: var(--font-small);
  text-align: left;
`;

export default function IntroBlock(node) {
  const { title, subTitle } = node;

  return (
    <>
      <Introduction>
        <IntroductionImage
          src={logoSvg}
          alt={title}
          className="introduction__background"
        />
        <Wrapper>
          <IntroductionTitle>{title}</IntroductionTitle>
          <IntroductionText
            dangerouslySetInnerHTML={{
              __html: subTitle.replaceAll('\n', '<br />'),
            }}
          />
        </Wrapper>
      </Introduction>
    </>
  );
}
