import React, {useRef} from 'react';
import imageUrlBuilder from '@sanity/image-url';
import styled from 'styled-components';
import client from './sanityClient';
import useScrollToPlace from "../utils/useScrollToPlace";

const builder = imageUrlBuilder(client);
const urlFor = (source) => builder.image(source);

const HeroContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: var(--spacing-xxl);
  position: relative;
  
  /* Portrait */
    @media only screen 
      and (min-device-width: 768px) 
      and (max-device-width: 1024px) 
      and (orientation: portrait) 
      and (-webkit-min-device-pixel-ratio: 1) {
        flex-direction: column;
        flex-basis: 70%;
    }

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    width: 70rem;
    height: 70rem;
    top: -45rem;
    left: -5rem;
    border-radius: 50%;
    background: #CBE6ED;
    z-index: 0;
    
    @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
        width: 30rem;
        height: 30rem;
        top: -10rem;
        left: -5rem;  
    }
    
    /* Portrait */
    @media only screen 
      and (min-device-width: 768px) 
      and (max-device-width: 1024px) 
      and (orientation: portrait) 
      and (-webkit-min-device-pixel-ratio: 1) {
        width: 50rem;
        height: 50rem;
        top: -30rem;
        left: -20rem;
    }
  }
  
  @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
      width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    margin-bottom: var(--spacing-m);
  }
`;

const HeroContentWrapper = styled.div`
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
     @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
        margin-top: var(--spacing-l);
        flex-basis: 100%;  
        order: 2;
    }
    
    /* Portrait */
    @media only screen 
      and (min-device-width: 768px) 
      and (max-device-width: 1024px) 
      and (orientation: portrait) 
      and (-webkit-min-device-pixel-ratio: 1) {
        margin-top: var(--spacing-l);
        flex-basis: 100%;  
        order: 2;
    }
`;

const HeroLogoWrapper = styled.div`
    width: 90%;
    flex-basis: 100%;
    margin-top: -4rem;
    
     @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
        flex-basis: 95%;
        width: 100%;
        margin: 0 auto; 
    }
    
    /* Portrait */
    @media only screen 
      and (min-device-width: 768px) 
      and (max-device-width: 1024px) 
      and (orientation: portrait) 
      and (-webkit-min-device-pixel-ratio: 1) {
        flex-basis: 95%;
        width: 100%;
        margin: 0 auto; 
    }
`

const HeroTitle = styled.h1`
  font-family: var(--font-large);
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 200;
  font-style: normal;
  width: 100%;
  z-index: 2;
  position: relative;
  color: #A0A0A0;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 2rem;
    margin-left: var(--spacing-m);
  }
`;

const HeroSubTitle = styled.h2`
  font-family: var(--font-large);
  font-size: 4.5rem;
  line-height: 1;
  font-weight: 600;
  font-style: normal;
  text-decoration: uppercase;
  width: 100%;
  z-index: 2;
  position: relative;
  color: #202020;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 4rem;
    margin-left: var(--spacing-m);
  }
`;

const HeroContent = styled.div`
    margin-top: var(--spacing-l);
    font-size: 1rem;
    font-weight: normal;
    font-family: var(--font-large);
    line-height: 1.4;
    position: relative;
    z-index: 2;
`;

const HeroMainImage = styled.img`
  width: 90%;
  object-fit: cover;
  position: relative;
  z-index: 1;
  opacity: 1;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    order: 1;
    width: 90%;
    margin-top: var(--spacing-l);
    margin-left: var(--spacing-m);
  }
`;

const FloatingLogo = styled.div`
    position: fixed;
    top: -6rem;
    left: 2rem;
    opacity: 0;
    transition: top .3s ease-in, opacity .2s ease-in;
    
    &.visible {
        top: 2rem;
        opacity: 1;
    }
    
    /* Portrait */
    @media only screen 
      and (min-device-width: 768px) 
      and (max-device-width: 1024px) 
      and (orientation: portrait) 
      and (-webkit-min-device-pixel-ratio: 1) {
        width: 20vw;
    }
    
    @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
        display: none;  
    }
`

export default function Hero(node) {
  const { title, mainImage, subTitle, introduction } = node;

  const logoRef = useRef();
  const heroRef = useRef();
  useScrollToPlace(heroRef, logoRef, '-200px');

  const heroMainImage = mainImage ? (
    <HeroMainImage src={urlFor(mainImage).url()} alt={title} />
  ) : (
    <></>
  );
  return (
      <>
        <HeroContainer
            ref={heroRef}
            data-sal="slide-up"
            data-sal-delay="500"
            data-sal-easing="ease-in">
            <a name={'home'} />
            <HeroLogoWrapper>
                {heroMainImage}
            </HeroLogoWrapper>
        </HeroContainer>
        <FloatingLogo ref={logoRef}>
          {heroMainImage}
        </FloatingLogo>
    </>
  );
}
