import React from 'react';
import imageUrlBuilder from '@sanity/image-url';
import styled from 'styled-components';
import client from './sanityClient';

const builder = imageUrlBuilder(client);
const urlFor = (source) => builder.image(source);

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const ImageContainer = styled.div`
  width: 100%;

  + callToAction {
    position: relative;
    margin-top: -30rem;
    z-index: 2;
  }
`;
export default function ImageSpacer(node) {
  const { image } = node;
  return (
    <ImageContainer className="imageSpacer">
      <Image src={urlFor(image).url()} title="Day.By.Day" />
    </ImageContainer>
  );
}
