import React from 'react';
import styled from 'styled-components';

const CallToActionContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  background: var(--primary-color);
  margin-top: var(--spacing-m);
`;

const CallToActionFooter = styled.div`
  background: #00b5f7;
  padding: var(--spacing-m) 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: -150px;
  z-index: 1;
`;

const FooterSlogan = styled.h3`
  font-family: var(--font-large);
  font-size: 4rem;
  line-height: 1;
  text-indent: -0.1rem;
  width: 50%;
  margin: 0 auto;
  color: var(--primary-color);
  text-align: center;
  margin-top: var(--spacing-m);
  text-transform: uppercase;
  font-style: italic;
  width: 30%;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
    font-size: 3rem;
  }
`;

const FooterCompany = styled.span`
  font-family: var(--font-large);
  font-size: 4rem;
  line-height: 1;
  text-indent: -0.1rem;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  font-style: italic;
  width: 30%;
  color: var(--thertary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  padding-bottom: var(--spacing-m);

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
    font-size: 3rem;
  }
`;

const FooterCompanyInverse = styled(FooterCompany)`
  color: var(--primary-color);
  width: auto;
  display: inline;
  padding: 0;
  margin: 0;
`;

export default function CallToActionSimple(node, companyName) {
  const companyNameSplitted = companyName.split('.');
  const { footerSlogan } = node;
  return (
    <>
      <CallToActionContainer>
        <CallToActionFooter>
          <FooterSlogan>{footerSlogan}</FooterSlogan>
          <FooterCompany>
            {companyNameSplitted.map((nameChunk, chunkIndex) => {
              if (chunkIndex < companyNameSplitted.length - 1) {
                return (
                  <span key={chunkIndex}>
                    {nameChunk}
                    <FooterCompanyInverse>.</FooterCompanyInverse>
                  </span>
                );
              }
              return <span key={chunkIndex}>{nameChunk}</span>;
            })}
          </FooterCompany>
        </CallToActionFooter>
      </CallToActionContainer>
    </>
  );
}
