import React, { useState } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import styled from 'styled-components';
import client from './sanityClient';

const builder = imageUrlBuilder(client);
const urlFor = (source) => builder.image(source);
const TestimonialsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  left: 0;
  z-index: 1;
  padding: var(--spacing-xxl) 0;
  background: #0b042b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const TestimonialsBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 1;
`;

const TestimonialsSlide = styled.div`
  background: var(--primary-color);
  box-sizing: border-box;
  width: 50%;
  position: relative;
  border-radius: 20px;
  z-index: 2;
  display: none;
  padding: var(--spacing-xl);
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
  }

  &.active {
    display: block;
  }

  :before {
    content: ' ';
    display: block;
    position: absolute;
    width: 50%;
    height: 50%;
    background: #fff;
    border-radius: 50%;
    top: 25%;
    left: 25%;
    z-index: -1;
  }

  :after {
    content: ' ';
    position: absolute;
    display: block;
    left: var(--spacing-xl);
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 40px solid transparent;

    border-top: 40px solid var(--primary-color);
    bottom: -40px;
  }
`;

const TestimonialsSlideSessions = styled.div`
  font-size: 1rem;
  color: var(--secondary-color);
  font-family: poppins, sans-serif;
  font-weight: 300;
  margin-bottom: var(--spacing-m);
`;
const TestimonialsSlideName = styled(TestimonialsSlideSessions)`
  font-weight: 800;
  margin-bottom: 0;
`;

const TestimonialsSlideContent = styled.div`
  &:before {
    content: '"';
    display: inline-block;
    font-size: 2rem;
    text-indent: -0.1rem;
    color: var(--secondary-color);
    font-family: futura-pt-condensed, sans-serif;
    margin-right: var(--spacing-s);
  }
  &:after {
    content: '"';
    display: inline-block;
    font-size: 2rem;
    text-indent: -0.1rem;
    color: var(--secondary-color);
    font-family: futura-pt-condensed, sans-serif;
    margin-left: var(--spacing-s);
  }
  font-size: 2rem;
  text-indent: -0.1rem;
  color: var(--thertary-color);
  font-family: futura-pt-condensed, sans-serif;
  font-weight: 400;
  font-style: italic;
  width: 80%;
  margin: 0 auto;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
    font-size: 1.3rem;
  }
`;

const TestimonialsSlideNavigator = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background-color: var(--primary-color);
  margin-right: var(--spacing-s);

  &.active {
    background-color: var(--secondary-color);
  }
`;

const TestimonialsNavigationContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: var(--spacing-xl) 0;
  z-index: 1;
  cursor: pointer;
`;

export default function Testimonials(node, currentTestimonials) {
  const { backgroundImage } = node;
  const testimonialsStatements = currentTestimonials.nodes;
  const testimonialsBackground = backgroundImage ? (
    <TestimonialsBackground
      src={urlFor(backgroundImage).url()}
      alt="Ervaringen van andere"
    />
  ) : (
    <></>
  );
  const allSlides = testimonialsStatements.map(
    (testimonialsStatement) => testimonialsStatement._id
  );
  const [state, setState] = useState({
    currentIndex: 0,
    allSlides,
  });

  const changeIndex = (index) => {
    setState({
      ...state,
      currentIndex: index,
    });
  };

  return (
    <>
      <TestimonialsContainer>
        {testimonialsBackground}
        {testimonialsStatements.map((testimonialStatement) => {
          const { _id, title, description, sessions } = testimonialStatement;
          return (
            <TestimonialsSlide
              key={_id}
              id={_id}
              className={`${
                state.allSlides[state.currentIndex] === _id ? 'active' : ''
              }`}
            >
              <TestimonialsSlideName>{title}</TestimonialsSlideName>
              <TestimonialsSlideSessions>{sessions}</TestimonialsSlideSessions>
              <TestimonialsSlideContent>{description}</TestimonialsSlideContent>
            </TestimonialsSlide>
          );
        })}
        <TestimonialsNavigationContainer>
          {testimonialsStatements.map((testimonialStatement, index) => {
            const { _id } = testimonialStatement;
            return (
              <TestimonialsSlideNavigator
                key={_id}
                onClick={() => {
                  changeIndex(index);
                }}
                className={`${
                  state.allSlides[state.currentIndex] === _id ? 'active' : ''
                }`}
              />
            );
          })}
        </TestimonialsNavigationContainer>
      </TestimonialsContainer>
    </>
  );
}
