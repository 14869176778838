import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import IntroBlock from '../components/IntroBlock';
import ScrollSlide from '../components/ScrollSlide';
import Testimonials from '../components/Testimonials';
import CallToAction from '../components/CallToAction';
import SEO from '../components/SEO';
import ImageSpacer from '../components/ImageSpacer';
import ContactForm from '../components/ContactForm';
import RatesOverview from '../components/RatesOverview';
import CallToActionSimple from '../components/CallToActionSimple';
import ContentBlock from '../components/ContentBlock';
import Hero from '../components/Hero';
import PlainSpacer from '../components/PlainSpacer';
import Paragraph from '../components/Paragraph';
import TreatmentsOverview from '../components/TreatmentsOverview';
import SlideToggle from '../components/SlideToggle';

let companyName;
let currentTestimonials;

const serializers = {
  types: {
    'content-block': ({ node }) => ContentBlock(node),
    hero: ({ node }) => Hero(node),
    introBlock: ({ node }) => IntroBlock(node, companyName),
    scrollSlide: ({ node }) => ScrollSlide(node),
    testimonialsBlock: ({ node }) => Testimonials(node, currentTestimonials),
    callToAction: ({ node }) => CallToAction(node, companyName),
    imageSpacer: ({ node }) => ImageSpacer(node),
    contactForm: ({ node }) => ContactForm(node),
    rates: ({ node }) => RatesOverview(node),
    callToActionSimple: ({ node }) => CallToActionSimple(node, companyName),
    plainSpacer: ({ node }) => PlainSpacer(node),
    paragraph: ({ node }) => Paragraph(node),
    treatmentsOverview: ({ node }) => TreatmentsOverview(node),
    slideToggle: ({ node }) => SlideToggle(node),
  },
};

export default function HomePage({ data }) {
  const { pages, setting } = data;
  companyName = setting.nodes[0].value;
  const [currentPage] = pages.nodes;
  return (
    <>
      <SEO
        title={currentPage.metaTitle}
        description={currentPage.metaDescription}
      />
      {pages.nodes.map((page) => (
        <BlockContent
          key={page.id}
          blocks={page._rawContent}
          serializers={serializers}
        />
      ))}
    </>
  );
}

export const query = graphql`
  query homePage {
    pages: allSanityPage(filter: { slug: { current: { eq: "home" } } }) {
      nodes {
        title
        id
        slug {
          current
        }
        metaDescription
        metaTitle
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
    }
    setting: allSanitySettings(filter: { title: { eq: "name" } }) {
      nodes {
        title
        value
        isActive
      }
    }
  }
`;
